define(['lodash', 'santa-components', 'componentsCore'],
    function (_, santaComponents, componentsCore) {
        'use strict';

        const {inlineContentMixin} = santaComponents.mixins;

        const SiteRegionContainer = {
            displayName: 'SiteRegionContainer',

            mixins: [inlineContentMixin, componentsCore.mixins.skinBasedComp, santaComponents.mixins.scrollMixin],

            getSkinProperties() {
                return {
                    inlineContent: {
                        'data-aid': 'SITE_REGION_CONTAINER',
                        children: this.getChildrenRenderer()
                    }
                };
            }
        };

        componentsCore.compRegistrar.register('wysiwyg.viewer.components.SiteRegionContainer', SiteRegionContainer);
        return SiteRegionContainer;
    });

